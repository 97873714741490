import React, { Component } from 'react';
import { RouterComponentProps } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import './Reports.scss';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';
import { getAllEpisodes } from '../../apiCalls/EpisodesService';
import { KiteLoader } from '@kite/react-kite';
import { getAllMovies } from '../../apiCalls/MoviesService';
import { renderTableDate } from '../../utils/helpers';

export interface IReportsState {
  csvData: any;
}

class Reports extends Component<RouterComponentProps, IReportsState> {
  state: IReportsState = {
    csvData: null,
  };

  static contextType = AnalyticsContext;

  componentDidMount() {
    const [analytics] = this.context;
    analytics.trackPageView('Reports');
    this.prepareData();
  }

  prepareData = async () => {
    const allEpisodes = await this.getEpisodeData();
    const allMovies = await this.getMovieData();
    const allAssets = [...allEpisodes, ...allMovies];
    const formattedAssets = allAssets.sort(
      (a, b) => +new Date(b.updatedOn) - +new Date(a.updatedOn)
    );
    const csvData = this.formatCSVDownloadData(formattedAssets);
    this.setState({ csvData });
  };

  getEpisodeData = async () => {
    const requestConfig = {
      order: 'asc',
      page: 0,
      sortBy: 'title',
      status: 'Production',
      size: -1,
    };
    const { allEpisodes } = await getAllEpisodes(requestConfig);
    return allEpisodes;
  };

  getMovieData = async () => {
    const requestConfig = {
      order: 'asc',
      page: 0,
      sortBy: 'title',
      status: 'Production',
      size: -1,
    };
    const { allMovies } = await getAllMovies(requestConfig);

    return allMovies;
  };

  formatCSVDownloadData = (data: any[]) => {
    const rows = data.map((item) => {
      const { seriesTitle, title, addedOn, updatedOn, services } = item;

      const ADOnly = services.find(
        (serv) => serv.type === 'Audio Description only'
      );
      const ADFull = services.find(
        (serv) => serv.type === 'Audio Description + Full Audio'
      );
      const CC = services.find((serv) => serv.type === 'Closed Captioning');

      return {
        type: seriesTitle ? 'Episode' : 'Movie',
        title: title,
        seriesTitle: seriesTitle || '-',
        dateAdded: renderTableDate(addedOn),
        datePublished: renderTableDate(updatedOn),
        AD: ADOnly ? ADOnly.file : '',
        ADandFull: ADFull ? ADFull.file : '',
        CC: CC ? CC.file : '',
      };
    });
    const headers = [
      { label: 'Type', key: 'type' },
      { label: 'Title', key: 'title' },
      { label: 'Series Title', key: 'seriesTitle' },
      { label: 'Date Added', key: 'dateAdded' },
      { label: 'Date Published', key: 'datePublished' },
      { label: 'Audio Desc Only', key: 'AD' },
      { label: 'AD + Full Audio', key: 'ADandFull' },
      { label: 'Closed Captioning', key: 'CC' },
    ];
    return { rows, headers };
  };

  render() {
    const { csvData } = this.state;

    return (
      <div>
        <main className="reports">
          <h2 className="reports__title">Reports</h2>
          {csvData ? (
            <CSVLink
              data={csvData.rows}
              headers={csvData.headers}
              filename={`AD_Report_${renderTableDate(new Date())}.csv`}
              className="reports__download"
            >
              Generate AD Report
            </CSVLink>
          ) : (
            <KiteLoader loaderTitle="Preparing Data" />
          )}
        </main>
      </div>
    );
  }
}

export default Reports;
