import React from 'react';
import { KiteButton, KiteIcon } from '@kite/react-kite';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './EpisodeResult.scss';
import { IService } from '../../utils/models';

export interface IEpisodeResultProps {
  episode: IEpisodeResult;
  onAdd: (id: string) => void;
  onUpdate?: (newMetadata: any) => void;
  resultType: 'add' | 'search' | 'update';
}

export interface IEpisodeResult {
  type: 'existing' | 'new';
  title: string;
  series: string;
  seriesType?: 'existing' | 'new';
  season: number;
  episode: number;
  seriesTmsid: string;
  episodeTmsid: string;
  description: string;
  posterUrl: string;
  seriesId?: string;
  id?: string;
  services?: IService[];
  status?: string;
  files?: any;
  number?: number;
  year?: number;
}

const EpisodeResult = ({
  episode: {
    type,
    title,
    series,
    season,
    episode,
    seriesTmsid,
    episodeTmsid,
    description,
    posterUrl,
  },
  onAdd,
  resultType,
  onUpdate,
}: IEpisodeResultProps) => {
  return (
    <article
      className={classNames({
        'episode-result': true,
        'episode-result--search': resultType === 'search',
      })}
    >
      <img
        className="episode-result__image"
        alt="Episode Poster"
        src={posterUrl}
        width="110"
        height="165"
      />

      <div className="episode-result__content">
        <h3 className="title">{title}</h3>
        <div className="info">
          <span className="label">Series</span>
          <p>{series}</p>
        </div>
        <div className="info">
          {season && <span className="label">Season {season}</span>}
          {season && episode && <span className="dot"></span>}
          {episode && <span className="label">Episode {episode}</span>}
        </div>
        <div className="info">
          <span className="label">Series TMSID </span>
          <p>{seriesTmsid}</p>
        </div>
        <div className="info">
          <span className="label">Episode TMSID </span>
          <p>{episodeTmsid}</p>
        </div>
        <div className="info">
          <span className="label">Description </span>
          <p>{description}</p>
        </div>
      </div>

      {resultType === 'add' && (
        <div className="episode-result__right">
          <span
            className={`episode-result__badge episode-result__badge--${type}`}
          >
            {type} Episode
          </span>

          {type === 'existing' ? (
            <Link
              className="episode-result__link"
              to={`/episodes/${episodeTmsid}`}
            >
              <span className="link__content">
                <KiteIcon name="plus-alt" />
                Update Episode
              </span>
            </Link>
          ) : (
            <KiteButton
              className="episode-result__button"
              type="outline"
              leftIcon="plus-alt"
              size="medium"
              onClick={() => onAdd(episodeTmsid)}
            >
              Add Episode
            </KiteButton>
          )}
        </div>
      )}

      {resultType === 'update' && (
        <div className="episode-result__right">
          <KiteButton
            className="episode-result__button"
            type="outline"
            leftIcon="plus-alt"
            size="medium"
            onClick={() =>
              onUpdate &&
              onUpdate({
                tmsId: episodeTmsid,
                title,
                newSeason: season,
                episode,
              })
            }
          >
            Update Episode
          </KiteButton>
        </div>
      )}
    </article>
  );
};

export default EpisodeResult;
