import React, { useContext, useEffect } from 'react';

import { KiteLoader } from '@kite/react-kite';

import getAuthorization from './getAuthorization';
import Routes from '../Routes';
import { Unauthorized } from '../../pages/index.ts';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider.tsx';
import useAuthStore from '../../store/useAuthStore.ts';

const KeyCloakService = () => {
  const [analytics] = useContext(AnalyticsContext);
  const authorization = useAuthStore((state) => state.authorization);
  const setAuthorization = useAuthStore((state) => state.setAuthorization);

  useEffect(() => {
    const getData = async () => {
      await getAuthorization(setAuthorization);
    };
    getData();
  }, []);

  useEffect(() => {
    if (authorization && authorization.roles.includes('access-admin')) {
      analytics.track('loginStop', {
        userId: authorization.user,
        opSuccess: true,
      });
    } else if (authorization) {
      analytics.track('loginStop', {
        opSuccess: false,
      });
    }
  }, [authorization]);

  if (!authorization) {
    return (
      <div className="display-loader">
        <KiteLoader />
      </div>
    );
  }
  if (authorization.roles && authorization.roles.includes('access-admin')) {
    return <Routes authorization={authorization} />;
  }

  return <Unauthorized />;
};

export default KeyCloakService;
