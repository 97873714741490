import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { featureFlags } from '../../config/featureFlags';

import {
  AllMovies,
  Movie,
  AllSeries,
  Series,
  Episode,
  AddMovie,
  NewAssets,
  Unauthorized,
  AddEpisode,
  AddSeries,
  AllCategories,
  Category,
  AddTitles,
  Search,
  AllSwimlanes,
  Swimlane,
  AllEpisodes,
  Reports,
  Notifications,
} from '../../pages';

import Nav from '../Nav';

const Routes = ({ authorization }) => {
  return (
    <>
      {window.location.pathname !== '/unauthorized' && (
        <Nav authorization={authorization} />
      )}
      <Switch>
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path="/new-assets" component={NewAssets} />
        <Route path="/movies/:movie_id" component={Movie} />
        <Route path="/movies" component={AllMovies} />
        <Route path="/episodes" component={AllEpisodes} />
        <Route
          path="/series/:series_id/episodes/:episode_id"
          component={Episode}
        />
        <Route path="/series/:series_id" component={Series} />
        <Route path="/series" component={AllSeries} />
        <Route path="/add-movie/:asset_id" component={AddMovie} />
        <Route path="/add-movie" component={AddMovie} />
        <Route path="/add-episode/:asset_id" component={AddEpisode} />
        <Route path="/add-episode" component={AddEpisode} />
        <Route path="/add-series" component={AddSeries} />
        <Route path="/:type/:cat_id/add" component={AddTitles} />
        <Route path="/categories/:cat_id" component={Category} />
        <Route path="/categories" component={AllCategories} />
        <Route path="/search" component={Search} />
        <Route path="/swimlanes/:swimlane_id" component={Swimlane} />
        <Route path="/swimlanes" component={AllSwimlanes} />
        <Route path="/reports" component={Reports} />
        {authorization.roles.includes('notification-admin') &&
          featureFlags.notifications && (
            <Route path="/notifications" component={Notifications} />
          )}
        <Route path="/">
          <Redirect to="/new-assets" />
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
