import axios, { AxiosRequestConfig } from 'axios';
import { IManualAssetFormState } from '../components/ManuallyAddAssetForm/ManuallyAddAssetForm';
import Analytics from '../analytics/Analytics';

export const createManualMetadata = async (
  metadata: IManualAssetFormState,
  analytics: Analytics,
  pid?: string
) => {
  const {
    movieTmsid,
    objectType,
    posterUrl,
    epicImageUrl,
    movieTitle,
    year,
    duration,
    description,
    rating,
    episodeTmsid,
    seriesTmsid,
    episodeTitle,
    seriesName,
    season,
    episode,
  } = metadata;

  const movieData = {
    tmsId: movieTmsid,
    posterUrl: posterUrl,
    epicUrl: epicImageUrl,
    title: movieTitle,
    releaseYear: Number(year),
    duration: Number(duration),
    rating: rating,
    description: description,
    objectType: objectType,
    updatedBy: pid ?? '',
    createdBy: pid ?? '',
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };
  const episodeData = {
    tmsId: episodeTmsid,
    seriesTmsId: seriesTmsid,
    posterUrl: posterUrl,
    epicUrl: epicImageUrl,
    series: seriesName,
    title: episodeTitle,
    season: season,
    episode: episode,
    releaseYear: year,
    rating: rating,
    description: description,
    objectType: objectType,
    updatedBy: pid ?? '',
    createdBy: pid ?? '',
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };

  const bodyData = objectType === 'movie' ? movieData : episodeData;

  const options: AxiosRequestConfig = {
    method: 'POST',
    data: bodyData,
    headers: { 'Content-Type': 'application/json' },
  };
  const sendTime = new Date().getTime();
  try {
    const res = await axios('/api/v1/manual-metadata', options);
    const receiveTime = new Date().getTime();
    const responseTime = receiveTime - sendTime;
    analytics.track('apiCall', {
      opSuccess: true,
      appApiResponseTimeMs: responseTime,
      appApiResponseCode: res.status,
      appApiPath: '/api/v1/manual-metadata',
      appApiQueryParameters: '',
      appApiHttpVerb: 'POST',
      appApiTraceId: 'Manual Metadata Creation',
      appApiDataCenter: 'SA Server',
      receivedTimestamp: receiveTime,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    const receiveTime = new Date().getTime();
    const responseTime = receiveTime - sendTime;
    analytics.track('apiCall', {
      opSuccess: false,
      appApiResponseTimeMs: responseTime,
      appApiPath: '/api/v1/manual-metadata',
      appApiHttpVerb: 'POST',
      appApiTraceId: 'Manual Metadata Creation',
      appApiDataCenter: 'SA Server',
      receivedTimestamp: receiveTime,
    });
    return null;
  }
};
