import React, { useState } from 'react';
import { KiteTabs } from '@kite/react-kite';
import { CreateNotification, SentNotificationTable } from '../../components';
import './Notifications.scss';

enum NotificationTabs {
  CreateNotification = 'Create Notification',
  SentNotification = 'Sent Notification',
}

const Notifications = () => {
  const [currentTab, setCurrentTab] = useState<NotificationTabs>(
    NotificationTabs.CreateNotification
  );
  const onSelectTab = (tab: string) => {
    setCurrentTab(tab as NotificationTabs);
  };
  return (
    <main className="notifications-container">
      <h1 className="notifications-container__title">Notifications</h1>
      <KiteTabs
        className="notification__tabs"
        tabs={[
          NotificationTabs.CreateNotification,
          NotificationTabs.SentNotification,
        ]}
        currentTab={currentTab}
        onSelectTab={onSelectTab}
      />
      {currentTab === NotificationTabs.CreateNotification && (
        <CreateNotification />
      )}
      {currentTab === NotificationTabs.SentNotification && (
        <SentNotificationTable />
      )}
    </main>
  );
};

export default Notifications;
