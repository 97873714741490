import React from 'react';
import { KitePagination, KiteTable } from '@kite/react-kite';
import './SentNotificationTable.scss';

const SentNotificationTable = () => {
  const columnData = [
    {
      label: 'Send Date',
      key: 'first',
    },
    {
      label: 'Notification Title',
      key: 'second',
    },
    {
      label: 'Message',
      key: 'third',
    },
    {
      label: 'Message ID',
      key: 'forth',
    },
  ];

  const tableData = [
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'New Titles Added!',
      third: 'We’ve added 5 new titles to our library!',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'Introducing Auto Resync.',
      third:
        'This feature picks up audio automatically after commercial breaks or unexpected pauses.',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'We’d Love Your Feedback!',
      third:
        'If you’ve been using the Auto Resync feature, we’d love to hear from you!',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'You Can Now Request Titles.',
      third:
        'This feature allows you to request titles that are not currently available in our library.',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'New Titles Added!',
      third: 'We’ve added 12 new titles to our library!',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'New Titles Added!',
      third: 'We’ve added 5 new titles to our library!',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'Introducing Auto Resync.',
      third:
        'This feature picks up audio automatically after commercial breaks or unexpected pauses.',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'We’d Love Your Feedback!',
      third:
        'If you’ve been using the Auto Resync feature, we’d love to hear from you!',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'You Can Now Request Titles.',
      third:
        'This feature allows you to request titles that are not currently available in our library.',
      forth: 'XBD1993AF',
    },
    {
      first: '07/14/23 - 2:08PM GMT',
      second: 'New Titles Added!',
      third: 'We’ve added 12 new titles to our library!',
      forth: 'XBD1993AF',
    },
  ];
  return (
    <div className="sent-notification-container">
      <KiteTable
        columns={columnData}
        tableData={tableData}
        useStripes
        className="sent-notification-table"
      />
      <KitePagination
        totalPages={8}
        currentPage={1}
        onNextPage={() => {
          return;
        }}
        onPrevPage={() => {
          return;
        }}
        onPageSelect={() => {
          return;
        }}
      />
    </div>
  );
};

export default SentNotificationTable;
