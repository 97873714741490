import create from 'zustand';

type authData = {
  fullName: string;
  isAdmin: boolean;
  pId: string;
  roles: string[];
  user: string;
};

interface AuthStore {
  authorization: authData | null;
  setAuthorization: (authData: authData) => void;
  hasRole: (role: string) => boolean;
}
const useAuthStore = create<AuthStore>((set, get) => ({
  authorization: null,

  setAuthorization: (authData) => set({ authorization: authData }),

  hasRole: (role) => {
    const auth = get().authorization;
    return (auth && auth.roles.includes(role)) ?? false;
  },
}));

export default useAuthStore;
