import React, { useState } from 'react';
import { KiteButton, KiteInput, KiteModal } from '@kite/react-kite';
import './CreateNotification.scss';

interface CreateNotificationInputs {
  [NotificationInputs.notificationTitle]: string;
  [NotificationInputs.notificationText]: string;
}

enum NotificationInputs {
  notificationTitle = 'notificationTitle',
  notificationText = 'notificationText',
}

const errorMessages = {
  [NotificationInputs.notificationTitle]: 'Please enter a message title',
  [NotificationInputs.notificationText]: 'Please enter a message',
};

const CreateNotification = () => {
  const [notificationInputs, setNotificationInputs] = useState<
    CreateNotificationInputs
  >({
    [NotificationInputs.notificationTitle]: '',
    [NotificationInputs.notificationText]: '',
  });
  const [notificationInputsError, setNotificationInputsError] = useState({
    [NotificationInputs.notificationTitle]: '',
    [NotificationInputs.notificationText]: '',
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleNotificationError = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { name, value },
    } = e;

    if (!value.trim()) {
      setNotificationInputsError((prev) => ({
        ...prev,
        [name]: errorMessages[name] ?? 'Required',
      }));
    } else
      setNotificationInputsError((prev) => ({
        ...prev,
        [name]: '',
      }));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { name, value },
    } = e;
    setNotificationInputs((prev) => ({ ...prev, [name]: value }));
    handleNotificationError(e);
  };

  const handleCreateNotification = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="create-notification-container">
      <h1 className="create-notification-container__title">
        Create Notification
      </h1>
      <div className="create-notification-container__input-container">
        <KiteInput
          label="Message Title"
          id="title"
          maxWidth="403px"
          name={NotificationInputs.notificationTitle}
          value={notificationInputs.notificationTitle}
          type="text"
          onChange={(e) => handleInputChange(e)}
          onBlur={(e) => handleNotificationError(e)}
          placeholder="New Titles Added!"
          errorMessage={
            notificationInputsError[NotificationInputs.notificationTitle]
          }
        />
        <div className="create-notification-container__input-container-multiline">
          <label htmlFor="message-text" className="multiline-input-label">
            Message Text
          </label>
          <textarea
            id="message-text"
            name={NotificationInputs.notificationText}
            className={`kite-form-control ${
              notificationInputsError[NotificationInputs.notificationText] &&
              'kite-form-control-danger'
            }`}
            rows={4}
            value={notificationInputs.notificationText}
            placeholder={`We’ve added X New titles to our library.`}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => handleNotificationError(e)}
          />
          {notificationInputsError[NotificationInputs.notificationText] && (
            <p className="kite-form-control-feedback" id="title-error">
              {notificationInputsError[NotificationInputs.notificationText]}
            </p>
          )}
        </div>
        <div className="create-notification-container__input-container-button">
          <KiteButton
            type="primary"
            size="xl"
            maxWidth="212px"
            disabled={
              !notificationInputs[NotificationInputs.notificationText].trim() ||
              !notificationInputs[NotificationInputs.notificationTitle].trim()
            }
            onClick={handleCreateNotification}
          >
            Create Notification
          </KiteButton>
        </div>
      </div>
      <KiteModal
        canShow={showModal}
        className="create-notification-summary-dialog"
        modalId="create-notification-summary"
        title="Notification Summary"
        onHide={handleCloseModal}
        disableBackgroundClose
      >
        <p className="create-notification-summary-dialog__body-text">
          Message Title:{' '}
          <span>
            {notificationInputs[NotificationInputs.notificationTitle]}
          </span>
        </p>
        <p className="create-notification-summary-dialog__body-text">
          Message Text:{' '}
          <span>{notificationInputs[NotificationInputs.notificationText]}</span>
        </p>
        <div className="create-notification-summary-dialog__modal-buttons">
          <KiteButton
            type="outline"
            size="large"
            buttonType="button"
            onClick={handleCloseModal}
          >
            Continue Editing
          </KiteButton>
          <KiteButton
            rightIcon="send-f"
            type="primary"
            size="large"
            buttonType="button"
          >
            Send Now
          </KiteButton>
        </div>
      </KiteModal>
    </div>
  );
};

export default CreateNotification;
