import React, { useContext, useState } from 'react';
import { KiteButton, KiteInput, KiteSelect } from '@kite/react-kite';
import useAuthStore from '../../store/useAuthStore';
import { createManualMetadata } from '../../apiCalls/MetadataService';
import './ManuallyAddAssetForm.scss';
import { AnalyticsContext } from '../../analytics/AnalyticsContextProvider';

const movieRatings = ['G', 'PG', 'PG-13', 'R', 'NC-17'];

const tvRatings = [
  'TV-Y',
  'TV-Y7',
  'TV-Y7 FV',
  'TV-G',
  'TV-PG',
  'TV-14',
  'TV-MA',
];

export interface IManualAssetFormState {
  episodeTmsid: string;
  seriesTmsid: string;
  movieTmsid: string;
  posterUrl: string;
  epicImageUrl: string;
  seriesName: string;
  episodeTitle: string;
  movieTitle: string;
  season: string | number;
  episode: string | number;
  year: string | number;
  rating: string;
  description: string;
  duration: string | number;
  objectType?: string;
}

interface ManuallyAddAssetFormProps {
  handleBackClick: () => void;
  formType: 'episode' | 'movie';
  handleNextClick: (formData: IManualAssetFormState) => void;
}

const ManuallyAddAssetForm = ({
  handleBackClick,
  formType,
  handleNextClick,
}: ManuallyAddAssetFormProps) => {
  const initialFormState = {
    episodeTmsid: '',
    seriesTmsid: '',
    movieTmsid: '',
    posterUrl: '',
    epicImageUrl: '',
    seriesName: '',
    episodeTitle: '',
    movieTitle: '',
    season: '',
    episode: '',
    year: '',
    rating: '',
    description: '',
    duration: '',
    objectType: formType,
  };
  const [analytics] = useContext(AnalyticsContext);
  const authorization = useAuthStore((state) => state.authorization);
  const [formState, setFormState] = useState<IManualAssetFormState>(
    initialFormState
  );
  const [formStateErrors, setFormStateErrors] = useState<
    Record<string, string>
  >(initialFormState);

  const ratings = formType === 'episode' ? tvRatings : movieRatings;

  const validateField = (name: string, value: string) => {
    if (formType === 'episode' && name === 'episodeTmsid' && !value.trim()) {
      return 'Please add Episode TMSID';
    }
    if (formType === 'episode' && name === 'seriesTmsid' && !value.trim()) {
      return 'Please add Series TMSID';
    }
    if (formType === 'movie' && name === 'movieTmsid' && !value.trim()) {
      return 'Please add TMSID';
    }
    if (name === 'posterUrl') {
      if (!value.trim()) {
        return 'Please add Poster URL';
      }
      try {
        new URL(value);
      } catch (_) {
        return 'Invalid URL format';
      }
    }
    if (name === 'epicImageUrl') {
      if (!value.trim()) {
        return 'Please add Epic Image URL';
      }
      try {
        new URL(value);
      } catch (_) {
        return 'Invalid URL format';
      }
    }
    if (formType === 'episode' && name === 'seriesName' && !value.trim()) {
      return 'Please add Series';
    }
    if (formType === 'episode' && name === 'episodeTitle' && !value.trim()) {
      return 'Please add Title';
    }
    if (formType === 'movie' && name === 'movieTitle' && !value.trim()) {
      return 'Please add Title';
    }
    if (
      formType === 'episode' &&
      name === 'season' &&
      (!value.trim() || isNaN(Number(value)))
    ) {
      return 'Please add Season';
    }
    if (
      formType === 'episode' &&
      name === 'episode' &&
      (!value.trim() || isNaN(Number(value)))
    ) {
      return 'Please add Episode';
    }
    if (
      name === 'year' &&
      (!value.trim() || isNaN(Number(value)) || Number(value) < 1900)
    ) {
      return 'Please add Year';
    }
    if (name === 'rating' && !value.trim()) {
      return 'Please add Rating';
    }
    if (
      formType === 'movie' &&
      name === 'duration' &&
      (!value.trim() || isNaN(Number(value)))
    ) {
      return 'Please add Duration';
    }
    return '';
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
    setFormStateErrors({ ...formStateErrors, [name]: '' });
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;

    const error = validateField(name, value);
    setFormStateErrors((prev) => ({ ...prev, [name]: error }));
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    Object.keys(formState).forEach((key) => {
      const error = validateField(key, formState[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
    setFormStateErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextButtonClick = async () => {
    if (validateForm()) {
      const manualResponse = await createManualMetadata(
        formState,
        analytics,
        authorization?.pId
      );

      if (!manualResponse) {
        return console.warn('Something went wrong, please try again');
      } else {
        handleNextClick && handleNextClick(formState);
      }
    }
  };

  return (
    <div className="manually-add-asset-form-container">
      <h1 className="manually-add-asset-form-container__heading">
        Manually Add Asset
      </h1>
      <div className="manually-add-asset-form-container__form">
        <div className="manually-add-asset-form-container__form-one">
          <h2 className="manually-add-asset-form-container__form-one-heading">
            Asset Metadata
          </h2>
          <p className="manually-add-asset-form-container__form-one-text">
            All fields required unless otherwise noted
          </p>
          {formType === 'episode' && (
            <>
              <KiteInput
                label="Episode TMSID:"
                maxWidth="472px"
                placeholder="MV017981150000"
                id="episodeTmsid"
                name="episodeTmsid"
                value={formState.episodeTmsid}
                onChange={(e) => handleInputChange(e)}
                errorMessage={formStateErrors.episodeTmsid}
                onBlur={(e) => handleBlur(e)}
              />
              <KiteInput
                label="Series TMSID:"
                maxWidth="472px"
                placeholder="MV017981150000"
                id="seriesTmsid"
                name="seriesTmsid"
                value={formState.seriesTmsid}
                onChange={(e) => handleInputChange(e)}
                errorMessage={formStateErrors.seriesTmsid}
                onBlur={(e) => handleBlur(e)}
              />
            </>
          )}
          {formType === 'movie' && (
            <KiteInput
              label="TMSID:"
              maxWidth="472px"
              placeholder="MV017981150000"
              id="movieTmsid"
              name="movieTmsid"
              value={formState.movieTmsid}
              onChange={(e) => handleInputChange(e)}
              errorMessage={formStateErrors.movieTmsid}
              onBlur={(e) => handleBlur(e)}
            />
          )}
        </div>
        <div className="manually-add-asset-form-container__form-two">
          <h2 className="manually-add-asset-form-container__form-two-heading">
            Metadata
          </h2>
          <p className="manually-add-asset-form-container__form-two-sub-heading">
            Images
          </p>
          <KiteInput
            label="Poster URL:"
            maxWidth="472px"
            placeholder="https://"
            id="posterUrl"
            name="posterUrl"
            value={formState.posterUrl}
            onChange={(e) => handleInputChange(e)}
            errorMessage={formStateErrors.posterUrl}
            onBlur={(e) => handleBlur(e)}
          />
          <KiteInput
            label="Epic Image URL:"
            maxWidth="472px"
            placeholder="https://"
            id="epicImageUrl"
            name="epicImageUrl"
            value={formState.epicImageUrl}
            onChange={(e) => handleInputChange(e)}
            errorMessage={formStateErrors.epicImageUrl}
            onBlur={(e) => handleBlur(e)}
          />
          <p className="manually-add-asset-form-container__form-two-sub-heading">
            Details
          </p>
          {formType === 'episode' && (
            <KiteInput
              label="Series:"
              maxWidth="472px"
              placeholder="Chesapeake Shores"
              id="seriesName"
              name="seriesName"
              value={formState.seriesName}
              onChange={(e) => handleInputChange(e)}
              errorMessage={formStateErrors.seriesName}
              onBlur={(e) => handleBlur(e)}
            />
          )}
          <KiteInput
            label="Title:"
            maxWidth="472px"
            placeholder={
              formType === 'episode' ? 'Exes Mark the Spot' : 'The Woman King'
            }
            id={formType === 'episode' ? 'episodeTitle' : 'movieTitle'}
            name={formType === 'episode' ? 'episodeTitle' : 'movieTitle'}
            value={
              formType === 'episode'
                ? formState.episodeTitle
                : formState.movieTitle
            }
            onChange={(e) => handleInputChange(e)}
            errorMessage={
              formType === 'episode'
                ? formStateErrors.episodeTitle
                : formStateErrors.movieTitle
            }
            onBlur={(e) => handleBlur(e)}
          />
          {formType === 'episode' && (
            <>
              <KiteInput
                label="Season:"
                maxWidth="472px"
                placeholder="1"
                id="season"
                name="season"
                type="number"
                value={formState.season}
                onChange={(e) => handleInputChange(e)}
                errorMessage={formStateErrors.season}
                onBlur={(e) => handleBlur(e)}
              />
              <KiteInput
                label="Episode:"
                maxWidth="472px"
                placeholder="10"
                id="episode"
                name="episode"
                type="number"
                value={formState.episode}
                onChange={(e) => handleInputChange(e)}
                errorMessage={formStateErrors.episode}
                onBlur={(e) => handleBlur(e)}
              />
            </>
          )}
          <KiteInput
            label="Year:"
            maxWidth="472px"
            placeholder="2022"
            id="year"
            name="year"
            type="number"
            value={formState.year}
            onChange={(e) => handleInputChange(e)}
            errorMessage={formStateErrors.year}
            onBlur={(e) => handleBlur(e)}
          />
          {formType === 'movie' && (
            <KiteInput
              label="Duration (in minutes):"
              maxWidth="472px"
              placeholder="75"
              id="duration"
              name="duration"
              type="number"
              value={formState.duration}
              onChange={(e) => handleInputChange(e)}
              errorMessage={formStateErrors.duration}
              onBlur={(e) => handleBlur(e)}
            />
          )}
          <KiteSelect
            id="rating"
            label="Rating"
            name="rating"
            maxWidth="472px"
            placeholder="PG 13"
            value={formState.rating}
            onChange={(e) => handleInputChange(e)}
            errorMessage={formStateErrors.rating}
          >
            {ratings.map((rating) => {
              return <option value={rating}>{rating}</option>;
            })}
          </KiteSelect>
          <label
            htmlFor="description"
            className="manually-add-asset-form-container__form-two-label"
          >
            Description (optional):
          </label>
          <textarea
            id="description"
            name="description"
            className={`kite-form-control manually-add-asset-form-container__form-two-text-area`}
            rows={4}
            value={formState.description}
            onChange={(e) => handleInputChange(e)}
            placeholder={
              'Abby and Trace will finally be moving forward, untangling a complicated love triangle'
            }
          />
        </div>
      </div>
      <div className="manually-add-asset-form-container__buttons">
        <KiteButton type="outline" onClick={() => handleBackClick()}>
          Back
        </KiteButton>
        <KiteButton type="primary" onClick={handleNextButtonClick}>
          Next
        </KiteButton>
      </div>
    </div>
  );
};

export default ManuallyAddAssetForm;
